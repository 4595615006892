<template>
  <div class="svg-button" @mouseenter="isHover = true" @mouseleave="isHover = false">
    <!-- :svg-name="isHover?svgHoverName?svgHoverName:svgName:svgName"
           如果svg图没有hover状态，则svg图的name就为svgName
           下面的按钮字体颜色也是一样的逻辑
    -->
    <svg-icon :svg-name="isHover?svgHoverName?svgHoverName:svgName:svgName"></svg-icon>
    <button
      :class="buttonClassName"
      :style="{'color': isHover?textHoverColor?textHoverColor:textColor:textColor}"
    >{{text}}</button>
  </div>
</template>

<script>
export default {
  name: "SvgButton",
  props: {
    svgName: {
      type: String,
      required: true
    },
    svgHoverName: {
      type: String
    },
    // 按钮文字
    text: {
      type: String,
      default: ""
    },
    textColor: {
      type: String,
      default: ""
    },
    buttonClass:{
      type: String,
      default: ""
    },
    textHoverColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isHover: false
    };
  },
  methods: {},
  computed: {
    buttonClassName() {
      if(this.buttonClass){
        return 'svg-button-text ' + this.buttonClass
      }
      return 'svg-button-text ';
    }
  }
};
</script>

<style scoped>
.svg-button {
  position: relative;
}

.svg-button-text {
  position: absolute;
  border: none;
  background-color: transparent;
  outline: none;
  /* width: 153px; */
  /* font-size: 16px; */
  /* font-weight: normal; */
  cursor: pointer;
  left: 0;
  top: 0;
  width: 90%;
  text-align: center;
  line-height: 100%;
  height: 100%;
  /* left: 45%;
            top: 47%;
            transform: translate(-50%, -50%); */
}
</style>